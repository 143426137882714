.Button {
  border-radius: 6px;
  background: #E8E8F1;
  border: none;
  padding: 10px 20px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
}

.Button:hover {
  background: #c9c9c9;
}

.DisabledButton {
  @extend .Button;
  opacity: 0.5;
  cursor: default;
}

.DisabledButton:hover {
  background: #E8E8F1;
}