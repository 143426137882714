//@import '../shared/calendar.css';
//@import "~bootstrap/dist/css/bootstrap.min.css";
//@import "charts/chartist";
//@import '~react-tabulator/lib/styles.css'; // required styles
//
//@import "~@fortawesome/fontawesome-free/css/all.min.css";
//
@import "src/styles/common";

table.portal-table {
    thead {
        border-top: 1px solid #dee2e6;
        border-bottom: 2px solid #dee2e6;
    }

    tr {
        border-bottom: 1px solid #dee2e6;
    }

    th {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 5px;
        white-space: nowrap;
    }

    td {
        padding: 10px 10px;
        white-space: nowrap;

    }

    thead {
        th {
            font-weight: bold;
            padding-top: 10px;
            padding-bottom: 7px;
        }
    }

    tbody {
        th {
            font-weight: normal;
        }
    }

    input {
        margin-top: 3px;
        margin-bottom: 3px;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        font-size: 1rem;
        line-height: 1.5;
        padding: 8px;
    }

    input:focus {
        outline: none;
        border: 1px solid var(--primary) !important;
    }

    .table-header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .table-header-title-arrow-down {
        width: 0;
        height: 12px;
        position: relative;
        top: 3px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #000;
    }

    .table-header-title-arrow-up {
        width: 0;
        height: 12px;
        position: relative;
        top: -3px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #000;
    }

    .table-header-title-arrow-unset {
        width: 0;
        height: 12px;
        position: relative;
        top: 3px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #bbb;
    }

    .table-header-title-arrow-down:hover, 
    .table-header-title-arrow-up:hover, 
    .table-header-title-arrow-unset:hover {
        cursor: pointer;
    }

    .sector-header {
        width: 230px;
        max-width: 230px;
    }

    .table-header-selector {
        width: 100%;
        font-weight: 100;
        font-size: 1rem;
        padding: 0;

        input {
            padding: 0;
            margin: 0;
        }
    }
}
