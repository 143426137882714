.Table {
  width: 100%;
  border-collapse: collapse;
  border: 0.5px solid rgb(220,220,220);
}

.Table td, th {
  border: 0.5px solid rgb(220,220,220);
  text-align: left;
  padding: 12px 12px;
}

.Table td {
  font-weight: lighter;
}