@import '~react-phone-input-2/lib/style.css';
//@import "~tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css";

$black: #000 !default;

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-Medium.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-Black.otf');
    font-weight: 900;
}

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-Bold.otf');
    font-weight: 700;
}

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-Book.otf');
    font-weight: 300;
}

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-Light.otf');
    font-weight: 100;
}

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-MediumItalic.otf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-BlackItalic.otf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-BoldItalic.otf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-BookItalic.otf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Circular';
    src: url('../../fonts/Circular/CircularStd-LightItalic.otf');
    font-weight: 100;
    font-style: italic;
}

html {
    font-size: 10pt;
    background-color: black;
}

.available-col {
  min-height: 100vh;
  .card {
      min-height: 100%;
      .card-body {
          overflow: auto;
      }
  }
}

.text-align-right {
    text-align: right;
}

.words {
    color: grey;
    font-style: italic;
    font-size: small;
}

.holding-issued {
}

.holding-pending {
    color: #17a2b8!important;
}

.table-total {
    font-weight: bold;
}

.table-dialog th {
    padding-right: 1ex;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .75;
  border-radius: .2rem;
}

.tabulator {
  font-variant-numeric: tabular-nums;
}

.ct-chart.reverse {
  display: flex;
  flex-direction: column-reverse;
}

.alert.alert-server {
  margin-bottom: 0;
  border-radius: 0;
}

.invalid-feedback {
  font-style: italic;
}

table.two-columns {
  border-collapse: collapse;

  & > tr, & > tbody > tr {
    padding: 5px;
    border: 1px solid #ccc;

    & > th {
      text-align: left;
      background: rgba($black, .03);
      font-weight: bold;
      padding: 10px;
    }

    & > td {
      text-align: left;
      padding: 10px;
      background: white;
    }
  }
}

.tabulator {
  .tabulator-tableHolder {
    overflow: hidden;
  }
}


.tabulator-row {
  &.expanded {
    color: grey;
  }

  .tabulator-cell {
    .tabulator-data-tree-control {
      background: white;

      &:hover {
        background: white;
      }
    }
  }
}

.list-group-flush .list-group-item:first-child {
    border-top: 0;
}

.treasuryspring {
    color: #00dc1e;
}

.ct-axis-title {
    color: rgba(0, 0, 0, 0.4);
    fill: rgba(0, 0, 0, 0.4);
}

.overlay {
  position: fixed;
  background: rgba(0,0,0,0.5);
  top: 0;
  z-index: 10;
}

.spanner {
  position:fixed;
  top: 50%;
  left: 0;
  width: 100%;
  display:block;
  text-align:center;
  color: #FFF;
  z-index: 11;
}

.thumb-icon {
  padding: .2rem .3rem;
  position: absolute;
  /* You may need to change top and right. They depend on padding/widht of .badge */
  top: -10px;
  right: -10px;
  border-radius: 50%;
}

.navbar-brand {
  .spinner-border {
    font-size: 1.25rem;
  }
}

.form-check {
  display: block;
}

.checkbox {
  page-break-inside: avoid;
}
